import { api, classNames, urls } from "cfg-base";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

interface Extras {
	icon?: string;
}

interface Link {
	disabled?: boolean;
	title: React.ReactElement | string;
	href?: string;
	extras?: Extras;
	type?: string;
	func?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => any;
}
type Direction = "left" | "right";
type Position = "absolute" | "fixed";

interface Props {
	altText?: string;
	className?: string;
	fixedDirection?: Direction;
	hideArrow?: boolean;
	icon?: string;
	image?: string;
	links: Link[];
	radioSelected?: number;
	t: Translator;
	title?: string;
	position?: Position;
}

export function DropdownMenu(props: Props) {
	const { fixedDirection, position } = props;

	// default direction to left & position to absolute
	const direction = useMemo(() => fixedDirection || "left", [fixedDirection]);
	const fixed = useMemo(() => position === "fixed", [position]);

	const [open, setOpen] = useState(false);
	const container = useRef<HTMLDivElement>(null);
	const dropdown = useRef<HTMLDivElement>(null);

	const handleWindowClick = (event: MouseEvent) => {
		const target = event.target;
		if (target instanceof HTMLElement && container !== null && container.current !== null) {
			if (target === container.current || container.current.contains(target)) {
				return;
			}

			setOpen(false);
		}
	};

	useEffect(() => {
		window.addEventListener("click", handleWindowClick);

		return () => {
			window.removeEventListener("click", handleWindowClick);
		};
	}, []);

	function handleClick(event: React.MouseEvent<HTMLElement>) {
		setOpen((prev) => !prev);
	}

	/**
	 * Close dropdown if link inside is clicked.
	 */
	function handleLinkClick(event: React.MouseEvent<HTMLElement>) {
		if (
			event.target instanceof HTMLElement &&
			event.target.classList.contains("dropdown-item")
		) {
			setOpen(false);
		}
	}

	const repositionDropdown = useCallback(() => {
		const rect = container.current!.getBoundingClientRect();

		if (dropdown.current) {
			dropdown.current.style.top = rect.bottom + "px";
			if (direction === "left") {
				dropdown.current.style.left = rect.left + "px";
			} else {
				dropdown.current.style.right = window.innerWidth - rect.right + "px";
			}
		}
	}, [direction]);

	useLayoutEffect(() => {
		if (open && fixed) {
			repositionDropdown();
		}
	}, [fixed, open, repositionDropdown]);

	useLayoutEffect(() => {
		if (fixed) {
			document.addEventListener("scroll", repositionDropdown);
			window.addEventListener("resize", repositionDropdown);
			return () => {
				document.removeEventListener("scroll", repositionDropdown);
				window.removeEventListener("resize", repositionDropdown);
			};
		}
	}, [fixed, repositionDropdown]);

	const { altText, className, icon, image, links, t, title, hideArrow, radioSelected } = props;
	const dropdownContainerClass = classNames(["dropdown-container", className && className]);
	const dropdownClass = classNames([`dropdown mod-${direction}`, fixed && "mod-fixed"]);
	const dropdownItemClass = classNames(["dropdown-item", "link", "link--hover"]);
	const MYCONFIGURA_URL = urls.getMyConfiguraOrigin();

	return (
		<div className={dropdownContainerClass} ref={container}>
			<button onClick={handleClick} type="button">
				{image && <img src={image} alt={altText} />}
				{icon && <i className={`svg-icon mod-${icon}`} />}
				{title && t(title)}
				{!hideArrow && (
					<i className={"svg-icon mod-black-svg mod-" + (open ? "up" : "down")} />
				)}
			</button>
			<div className={dropdownClass} role="menu" onClick={handleLinkClick} ref={dropdown}>
				{open &&
					links.map((item, index) => {
						const title = item.title;
						const href = item.href;
						const extras = item.extras || {};
						const type = item.type;
						const func = item.func;
						let optionProps: Object = {};
						const titleEl = typeof title === "string" ? t(title) : title;

						if (!href && !type) {
							return (
								<span className="dropdown-header" key={index}>
									{extras.icon && <i className={`svg-icon mod-${extras.icon}`} />}
									{titleEl}
								</span>
							);
						}

						if (type === "link" && href) {
							if (title === "label.log_out") {
								optionProps = {
									className: `${dropdownItemClass} ut-hover-pointer`,
									onClick: async () => {
										api.call("auth/logout", {}).then(() => {
											window.location.href = MYCONFIGURA_URL;
										});
									},
								};
							} else {
								optionProps = { className: dropdownItemClass, href };
							}
						} else {
							optionProps = {
								className: classNames([
									dropdownItemClass,
									!item.disabled && "ut-hover-pointer",
									item.disabled && "ut-disabled",
								]),
								onClick: !item.disabled
									? (ev: React.MouseEvent<HTMLAnchorElement>) => {
											func?.(ev);
											setOpen(false);
									  }
									: (ev: React.MouseEvent<HTMLAnchorElement>) => {
											ev.stopPropagation();
									  },
							};
						}

						return (
							<a key={index} role="menuitem" {...optionProps}>
								{href && radioSelected !== undefined && (
									<input
										onClick={() => (window.location.href = href)}
										type="radio"
										readOnly
										className="dropdown-fake-radio"
										checked={radioSelected === index}
									/>
								)}
								{extras.icon && <i className={`svg-icon mod-${extras.icon}`} />}
								{titleEl}
							</a>
						);
					})}
			</div>
		</div>
	);
}
