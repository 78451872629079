import { urls } from "cfg-base";
import { Col, Container, Row, SocialMedia, Text, useTranslation } from "components";
import React from "react";
import { SubscribeForm } from "../pages/SubscribeForm";

const CONFIGURA_URL = urls.getConfiguraOrigin();
const YEAR = new Date().getFullYear();

interface Props {
	marketing?: boolean;
	t?: Translator;
}

const FOOTER_SCOPES = ["footer", "social"];
export function Footer(props: Props) {
	const { marketing } = props;

	const t = useTranslation(FOOTER_SCOPES);

	return (
		<footer id="configura-footer">
			<Container>
				<Row>
					{marketing && <MarketingFooter t={t} />}
					<Col className="is-12 legal">
						<p className="ut-margin-top-0 ut-text-center">
							Copyright &copy; {YEAR} Configura Sverige AB.{" "}
							{t("footer.all_rights_reserved")}
							{"  "}
							<br className="ut-hide-on-md-up" />
							<a
								className="link link--white link--border-link--white"
								href={CONFIGURA_URL + "/legal-terms"}
							>
								{t("footer.legal_terms")}
							</a>{" "}
							|{" "}
							<a
								className="link link--white link--border-link--white"
								href={CONFIGURA_URL + "/privacy-notice"}
							>
								{t("footer.privacy_notice")}
							</a>{" "}
							|{" "}
							<a
								className="link link--white link--border-link--white"
								href={CONFIGURA_URL + "/terms-of-use"}
							>
								{t("footer.terms_of_use")}
							</a>
							<span className="ut-hide">
								<a
									className="link link--white link--border-link--white"
									href={CONFIGURA_URL + "/sitemap"}
								>
									{t("footer.site_map")}
								</a>{" "}
								|
							</span>
						</p>
					</Col>
					<img
						className="ut-responsive-image ut-invisible beagle"
						src={`${urls.STATIC_URL}/web/img/footer/beagle.png`}
						alt="beagle"
					/>
				</Row>
			</Container>
		</footer>
	);
}

interface MarketingFooterProps {
	t: Translator;
}

function MarketingFooter(props: MarketingFooterProps) {
	const { t } = props;

	return (
		<>
			<Col className="is-12-on-xs is-5-on-lg">
				<h3 className="title-5 mod-medium">{t("footer.get_in_touch")}</h3>
				<p>
					<Text content={t("footer.contact_us")} />
				</p>
				<SocialMedia account="configura" t={t} />
			</Col>
			<Col className="is-12-on-xs is-5-on-lg is-offset-1-on-lg">
				<h3 className="title-5 mod-medium">{t("footer.subscribe")}</h3>
				<p>{t("footer.subscribe_text")}</p>
				<SubscribeForm
					t={t}
					url="https://configura.us3.list-manage.com/subscribe/post?u=274cbd31f2421263cc2d550eb&amp;id=0bc0c64c32"
				/>
			</Col>
		</>
	);
}
