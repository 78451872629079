import { Agreement } from ".";
import { fetchJSON } from "./helpers";
import { TranslatableError, toResult } from "./results";
import * as s3 from "./s3";
import * as types from "./types";
import * as briefcaseTypes from "./types/briefcase";
import * as manufacturerTypes from "./types/manufacturer";
import { ManufacturerLW } from "./types/manufacturer";
import { PmDataHW, PmDataLW, PmDataLinks } from "./types/projectMatrix";
import { RecentSearchEntity, SearchLicense, SearchTypes } from "./types/search";
import * as siteAdminTypes from "./types/siteAdmin";
import * as subscriptionTypes from "./types/subscription";
import * as urls from "./urls";

export interface SuccessResponse {
	success: string | TranslatableMessage;
}

export interface ErrorResponse {
	error: string | TranslatableMessage;
}

export interface CloseAccountStatusResponse {
	task: Option<{
		taskId: number;
		executeAtTime: string;
	}>;
}

export interface CloseAccountResponse extends SuccessResponse, CloseAccountStatusResponse {}

export interface AbortCloseAccountResponse extends SuccessResponse {}

interface EmailCheckResponse {
	type: number;
	app_id?: string;
}

export interface TfaRequiredResponse {
	tfa_required: boolean;
}

export interface TranslatableResponse {
	message: string;
	values?: TranslationValues;
}

export interface InfoResponse {
	info: string | TranslatableMessage;
}

export type Language = "en" | "zh" | "de" | "ja";

export interface ActiveLicenseUser extends UserLW {
	licenses: License[];
	lastUsed: number;
	lastUsedFormatted: string;
	info: UserLicenseInfo[];
}

export type FileLocation = "EU" | "NA" | "CN" | "AS";

export interface Country {
	countryId: number;
	code: string;
	name: string;
	fullName: string;
	iso3: string;
	number: number;
	continentCode: string;
	displayOrder: number;
}

export interface CountryResponse {
	country: Country;
}

export interface CountriesResponse {
	countries: Country[];
}

export interface CountryType {
	country: string;
	country_name: string;
}

export interface USStateType {
	abbrev: string;
	name: string;
}

export interface USState extends USStateType {
	stateId: number;
}

export interface StateResponse {
	state: USState;
}

export interface StatesResponse {
	states: USState[];
}

interface ContryStateResponse {
	countries: CountryType[];
	us_states: any;
}

interface CurrencyResponse {
	currencies: string[];
}

export interface CurrenciesResponse {
	currencies: Currency[];
}

interface UploadSaveResponse {
	fileId: number;
	fileName: string;
	url: string;
}

interface UploadCredentialsResponse {
	creds: s3.Credentials;
	formExtra: s3.FormExtra;
	url: string;
}

export interface FilesResponse {
	files: s3.S3File[];
}

export type DriverType = "laptop" | "workstation";

export interface DriversResponse {
	drivers: Driver[];
	isRedway: boolean;
	displayName: string;
}

export interface Driver {
	name: string;
	url: string;
	type: DriverType;
}

export interface UserAdminResponse {
	adminUser?: User;
	user?: User;
}

export interface TestCrashResponse {
	debugStr: string;
}

// Catalogues

export interface PortfolioLW {
	id: number;
	name: string;
	description: string;
	manufacturerId: number;
	manufacturerName?: string;
	hasExtension?: boolean;
}

export interface ExtensionPortfolio extends PortfolioLW {
	installed?: number;
}

export interface Portfolio extends PortfolioLW {
	active: boolean;
	activityLogs: ProjectLog[];
	admins: UserMW[] | null;
	approved: boolean;
	changed: number;
	created: number;
	extensionId: number | null;
	extensionName: string | null;
	locations: string;
	manufacturer: number;
	manufacturerName: string;
	parent: number | null;
	public: boolean;
	published: boolean;
	size: string;
	stage: PortfolioStage | null;
	status: "OK" | "DELETED";
	uid: number;
	userAccess: number | null;
	versionCid: number;
	versionCreated: number;
	versionModified: number;
	versionOfCid: number;
	versionPortfolios: Portfolio[] | null;
	visible: boolean;
}

export interface PortfolioStage {
	cid: number;
	created: number;
	debug: number;
	deleted: number | null;
	modified: number;
}

export interface PortfolioResponse {
	portfolio: Portfolio;
}

export interface PortfoliosResponse {
	portfolios: Portfolio[];
	totalSize?: string;
}

export interface PortfolioAdminsResponse {
	admins: UserMW[];
}

export interface PortfolioDetailsResponse {
	admins?: UserMW[];
	job: PortfolioJob | null;
	urldb: PortfolioFile | null;
}

export interface PortfolioJob {
	created: number;
	error: string;
	type: string;
}

export interface PortfolioFile {
	modified: number;
	name: string;
	url: string;
}

// Briefcases
export interface Briefcase {
	adminCount: number;
	created: number;
	description: string;
	favorite: boolean;
	id: number;
	isAdmin: boolean;
	isConfiguraEmployee: boolean;
	isDeleteable: boolean;
	isLeaveable: boolean;
	latestPost: BriefcasePost | null;
	logoS3: number | null;
	logoUrl: string | null;
	name: string;
	openForComment: boolean;
	openForPost: boolean;
	subscriptions: BriefcaseSubscription[];
	tags: briefcaseTypes.BriefcaseTag[];
	trainingDate: number | null;
	showMembers: boolean;
	uid: number;
	visited: number;
}

type BriefcaseSubscription = "group" | "allactivity" | "comments";

export interface BriefcaseResponse {
	briefcase: Briefcase;
}

export interface BriefcaseListResponse {
	briefcases: Briefcase[];
}

export interface BriefcaseInvitesResponse {
	invites: UserLW[];
}

export interface BriefcaseInvitesPendingResponse {
	invites: briefcaseTypes.BriefcaseInvite[];
}

export interface BriefcasePostLikeResponse {
	actionType: "add" | "edit" | "remove";
	likes?: UserLW[];
	parentPost?: BriefcasePost;
	post: BriefcasePost;
	success?: string;
}

export interface BriefcaseNode {
	created: number;
	disableComments: boolean;
	edited: number | null;
	id: number;
	isLiked: boolean;
	pinned: boolean;
	published: number;
	sticky: boolean;
	strippedText: string;
	text: string;
	title: string;
	totalLikes: number;
	parentId: number | null;
	user: UserLW;
}

export interface BriefcasePostCommentResponse {
	comments: BriefcasePost[];
}

export interface BriefcasePost extends BriefcaseNode {
	modifiedBy?: UserLW;
	attachments: briefcaseTypes.BriefcaseAttachment[];
	briefcaseId: number;
	comments: BriefcasePost[];
	likes: UserLW[];
}

// Todo: larhe, count
export interface BriefcasePostResponse {
	actionType?: "add" | "edit" | "remove";
	parentPost?: BriefcasePost;
	post: BriefcasePost;
	success: string;
}

export interface ListBriefcasePostResponse {
	posts: BriefcasePost[];
	postsLeft: number;
}

export interface ListBriefcaseFilesResponse {
	files: briefcaseTypes.BriefcaseAttachment[];
	filesLeft: number;
}

export interface BriefcaseExtensionResponse {
	extensionName: string;
	extensionId: number;
}

export type MemberType = "admin" | "member";

export interface BriefcaseListMembersResponse {
	members: UserLW[];
	membersTotal: number;
	membersLeft: number;
}

export interface ExportResponse {
	url: string;
}

// Awards
export interface AwardSubmissionData {
	client?: string;
	projectSize?: string;
	summary?: string;
	timeFrame?: string;
}

export interface AwardSubmission {
	awardId: number;
	categoryId: number;
	company: string;
	country: string;
	data?: AwardSubmissionData;
	fileUrl: string;
	fileExtraUrl?: string;
	fileDrawingUrl?: string;
	finalist: boolean;
	id: number;
	manufacturer: string;
	modified: number;
	name: string;
	placement: number | null;
	s3File: number;
	s3ExtraFile?: number;
	s3DrawingFile?: number;
	industry?: string;
	s3FileName: string;
	s3ExtraFileName?: string;
	s3DrawingFileName?: string;
	submitted: boolean;
	timeWithCet: string;
	title: string;
	uid: number;
}

export type AwardCategoryType = "image" | "pdf" | "video" | "vr-image";

export interface AwardCategory {
	awardId: number;
	cardImage?: number;
	cardImageUrl?: string;
	cardText: string;
	created: number;
	extraData: boolean;
	id: number;
	internalVoting: boolean;
	needIndustry: boolean;
	modified: number;
	name: string;
	noExternalRendering: boolean;
	requireDrawing: boolean;
	slug: string;
	type: AwardCategoryType;
}

export interface GetAwardResponse {
	active: boolean;
	categories: AwardCategory[];
	created: number;
	id: number;
	modified: number;
	mySubmissions?: AwardSubmission[];
	name: string;
	registrationCloses: number;
	registrationClosesVisual: string;
	registrationOpens: number;
	registrationOpensVisual: string;
	showPreviousWinners: boolean;
	submissions?: AwardSubmission[];
	votingCloses: number;
	votingClosesVisual: string;
	votingOpens: number;
	votingOpensVisual: string;
}

export interface GetAwardsResponse {
	awards: GetAwardResponse[];
}

export interface GetAwardSubmissionResponse {
	submission?: AwardSubmission;
}

interface ConfirmAwardVote {
	error?: string;
	info?: string;
	success?: string;
}

export interface AwardVote {
	categoryId: number;
	confirmed: boolean;
	created: number;
	email: string;
	id: number;
	modified: number;
	removed: boolean;
	submissionId: number;
}

interface GetAwardVoters {
	voters: AwardVote[];
}

export interface AwardFinalists {
	award_name: string;
	categories: AwardCategoryFinalist[];
}

export interface AwardCategoryFinalist {
	category_name: string;
	finalists: AwardFinalist[];
	slug: string;
	type: AwardCategoryType;
}

export interface AwardFinalist {
	company: string;
	country: string;
	file_url: string;
	file_preview_url: string;
	file_thumb_url: string;
	name: string;
	placement: number | null;
	title: string;
	winner_pic_url: string | null;
}

export interface GetPreviousAwardFinalistResponse {
	awards: AwardFinalists[];
}

// Campaign
export interface CampaignParticipant {
	created: number;
	email: string;
	id: number;
	licenseLogs?: string[];
	name: string;
	referred?: string;
	verify: boolean;
	designSoftware?: string;
}

export interface CampaignParticipantsResponse {
	participants: CampaignParticipant[];
}

export interface Campaign {
	campaignId: number;
	name: string;
	startDate: number;
	endDate: number;
	created: number;
	licenseAllocationPreset?: LicenseAllocationPreset;
}

export interface CampaignResponse {
	campaign: Campaign;
}

export interface LicenseAllocationPreset {
	id: number;
	editionId: number;
	extensionIds: number[];
	created: number;
}

export interface CampaignsResponse {
	campaigns: Campaign[];
}

// Contacts

export interface ContactsResponse {
	contacts: Contact[];
}

export interface ContactsExtensionResponse {
	contacts: ContactExtension[];
}

export interface Contact {
	id: number;
	title: string;
	roleId: number;
	manufacturerId?: number;
	manufacturerName?: string;
	uid: number | null;
	email: string;
}

export interface ContactExtension extends Contact {
	extensionId: number;
	extensionName?: string;
}

export interface ManufacturerResponse {
	createAccess: boolean;
	deleteAccess: boolean;
	developerAdminAccess: boolean;
	manufacturer: ManufacturerDataPropsMW;
}

export interface Manufacturers {
	[id: string]: ManufacturerDataPropsMW;
}

export interface ProjectsResponse {
	createAccess: boolean;
	manufacturers: Manufacturers;
}

export interface ProjectAccessResponse {
	users: UserMW[];
}

export interface ProjectsFavoriteResponse {
	manufacturer_favorites: number[];
}

export interface AddProjectFormData {
	pkgPrefix: string;
	editions: EditionInfo[];
	targetExtensions: ExtensionTypeLW[];
}

export interface EditionsResponse {
	editions: ExtensionInfo[];
}

export interface AdminsObject {
	[key: number]: UserMW;
}

export type AccessName = "extensionDeveloper" | "manufacturer_id" | "uid" | AccessType;

export type CurrentAccess = { [K in AccessName]: number };

export type AccessType = "owner" | "developer" | "user" | "economy" | "training" | "project";

export type AccessAdmins = { [K in AccessType]: number[] };

export interface ExtensionStatusType {
	released: number;
	score: number;
	productsStatus: ProductStatusType[];
	warnings?: string[];
}

export interface ProductStatusType {
	productId: number;
	released: number;
	score: number;
	warnings?: string[];
}

export interface SupportDetails {
	description: string;
	email: string;
	name: string;
	phone: string;
	url: string;
	uuid?: string;
	alternativeText?: string;
}

export type ExtensionIndustry = "office" | "kitchen" | "configura" | "";

export interface ExtensionTypeLW {
	id: number;
	logoUrl: string;
	manufacturerId: number;
	manufacturerName: string;
	name: string;
	slug?: string;
	standalone: boolean;
	status: ExtensionStatusType;
	subExtension: boolean;
	subExtensions?: ExtensionTypeLW[];
	isEdition: boolean;
	isContentPack: boolean;
	contentPackTargetExtensionId?: number;
	contentPackTargetExtensionName?: string;
	editionType: string | null;
	industryLibrary: boolean;
	isTargetExtension: boolean;
	skipExtensionReview: boolean;
}

export interface ExtensionTypeMW extends ExtensionTypeLW {
	activityLogs: ProjectLog[];
	developers: User[];
	pkg?: string;
	products: EditionProduct[];
	releaseDate?: number | null;
	sentryTeam?: string;
	supportType?: "basic" | "superior";
	supportContacts: SupportDetails[];
}

export interface ExtensionTypeHW extends ExtensionTypeMW {
	allowAll: boolean;
	bundle: boolean;
	created: number;
	createdByUid: number;
	deactivate: DeactivateStatus;
	forceUninstall: ForceUninstallStatus;
	gitLabTag?: string;
	iconFileId?: number;
	iconURL?: string;
	industry: ExtensionIndustry;
	infoForUser?: string;
	jiraTag?: string;
	linkedSentryExtension?: number;
	major: boolean;
	manufacturer: ManufacturerDataPropsMW;
	parent?: number;
	platform: string;
	retired: boolean;
	retirementDate?: number;
	setUninstall: boolean;
}

export interface ExtensionType {
	activityLogs?: ProjectLog[];
	bundle: number;
	developers?: User[];
	extensionId: number;
	iconUrl?: string;
	id: number;
	industry: "office" | "kitchen" | "configura" | "";
	isEdition?: number;
	linkedSentryExtension?: number;
	logoUrl: string;
	manufacturer?: ManufacturerDataPropsMW; // Change this type since its not completly accurate
	manufacturerId: number;
	name: string;
	parent?: number;
	pictureS3?: number;
	pkg?: string;
	platform: string;
	products: EditionProduct[];
	releaseDate: number;
	sentryTeam?: string;
	standalone: number;
	status: ExtensionStatusType;
	subExtension: number;
	subExtensions?: ExtensionType[];
	supportAgreement?: string;
	supportContacts?: SupportDetails[];
	supportOption: "agreement" | "contact" | "decide_later";
}

export interface Point2D {
	x: number;
	y: number;
}

export interface LicenseAndVersionStats {
	license: Point2D[];
	version: Point2D[];
	editionId: number;
}

export type ExtensionStatistics = {
	[key: string]: LicenseAndVersionStats;
};

export interface ExtensionStatisticsResponse {
	statistics: ExtensionStatistics;
}

export interface ManufacturerDataPropsMW extends manufacturerTypes.ManufacturerMW {
	accessAdmins: AccessAdmins;
	activityLogs: ProjectLog[];
	admins: AdminsObject;
	apiKeys: string[];
	currentAccess: CurrentAccess;
	extensions: ExtensionTypeMW[];
	hasRoyalty: boolean;
	projectAccessUsers: UserMW[];
	availableProjectAccessLicenses: number;
}

export interface ExtensionsDataProps extends ManufacturerDataPropsMW {
	alternative?: string;
}

export interface AllManufacturersResponseLW {
	manufacturers: manufacturerTypes.ManufacturerLW[];
}

export interface AllManufacturersResponseMW {
	manufacturers: manufacturerTypes.ManufacturerMW[];
}

export interface AllManufacturersResponse {
	manufacturers: ManufacturerDataPropsMW[];
}

interface ActivityLogResponse {
	extension?: ExtensionType;
	logs: ProjectLog[];
	manufacturer?: ManufacturerDataPropsMW;
}

export interface ExtensionResponse {
	extension: ExtensionTypeHW;
	deleteAccess: boolean;
	approvalAccess: boolean;
	developerAdminAccess: boolean;
}

export interface ExtensionVersionsExportResponse {
	url: string;
}

export interface ExtensionPopulationResponse {
	coordinates: PopulationCoordinate[];
	max: number;
}

interface PopulationCoordinate {
	lat: number;
	long: number;
	population: number;
}

export interface ActiveLicenseResponse {
	currency: string;
	id: number;
	name: string;
	no_bought: number;
	no_rental: number;
	price_bought: number;
	price_bought_total: number;
	price_rental: number;
	price_rental_total: number;
	price_total: number;
}

interface FormErrorResponse {
	formError: string;
	validation: { [key: string]: string };
}

export interface CETXRegistrationSettings {
	welcomeEvent: boolean;
	workshop: boolean;
}

export interface CETXScheduleSettings {
	dynamic: boolean;
	hearts: boolean;
}

export interface CETXSettings {
	registration: CETXRegistrationSettings;
	schedule: CETXScheduleSettings;
}

export interface ProductCategory {
	categoryId: number;
	name: string;
}

export interface CategoriesResponse {
	categories: ProductCategory[];
}

export type ExtensionReviewStatus =
	| "Test rejected"
	| "Test approved"
	| "Test in progress"
	| "New"
	| "Closed";
export interface EditionProduct extends Product {
	edition: types.EditionLW;
	approved: boolean;
	buyable: boolean;
	canUseExtension: boolean;
	categories?: ProductCategory[];
	reviewed: boolean;
	reviewStatus?: ExtensionReviewStatus;
	status?: ProductStatusType;
}

interface EditionProductResponse {
	product: EditionProduct;
}

interface EditionProductsResponse {
	products: EditionProduct[];
}

export interface Event {
	location: string;
	attendees?: number;
	sessions?: Session[];
	speakers?: Speaker[];
	title: string;
	id: number;
	start_date: string;
}

export interface EventTag {
	id: number;
	name: string;
}

export interface EventsResponse {
	events: Event[];
}

export interface Forum {
	alias: string;
	allow_anonymous_posts: boolean;
	created: number;
	created_by: number;
	deleted: boolean;
	description: string;
	id: number;
	link: string;
	last_post: ForumLastPost;
	name: string;
	number_of_posts: number;
	number_of_threads: number;
	type: string;
	visible: boolean;
}

export interface ForumLastPost {
	author: string;
	link: string;
	title: string;
	datetime: number;
}

export interface ForumsResponse {
	forums: Forum[];
}

export interface Log {
	adminUid: number | null;
	affectedUid: number | null;
	created: number;
	doerUid: number;
	id: number;
	log: string;
	details?: string | TranslatableMessage | null;
	refId: number | null;
	type: string;
}

export interface ProjectLog extends Log {
	extensionId: number | null;
	manufacturerId: number | null;
}

export interface LogWithUsers extends Log {
	affectedUser: UserLW;
	doerUser: UserLW;
}

interface LogsWithUsersResponse {
	logs: LogWithUsers[];
}

interface BuildCenterVersionLogsResponse {
	logs: LogWithUsers[];
	totalLogs: number;
}

export interface Product extends types.ProductHW {
	extensions?: ExtensionTypeHW[];
	priceModels: PriceModelPerPlan;
	productCurrency: keyof ProductPlansPrice;
	productStatus: ProductStatusType;
}

export interface ProductPlansPrice {
	GBP: number;
	SEK: number;
	USD: number;
}

export interface UpcomingEventsResponse {
	events: Event[];
}

export type ClassType =
	| "scheduled"
	| "selfpaced"
	| "livewebinar"
	| "selfpacedwebinar"
	| "certification"
	| "devCertification";

export interface TrainingClassDetail {
	anchor?: string;
	classType?: ClassType;
	datetime: string;
	description: string;
	disableRegister: boolean;
	extensionId?: number;
	id: number;
	instructor: string;
	instructorEmail: string;
	isOpenForRegistration: boolean;
	location: string;
	manufacturer: string | null;
	manufacturers: TrainingManufacturer[];
	name: string;
	prerequisites: string[];
	seatLeft?: number;
	seatPrice?: string;
	shortDescription: string;
	skillLevel: string;
	hideIdcec: boolean;
	productId: number | null;
	title: string;

	[key: string]:
		| null
		| boolean
		| string
		| string[]
		| number
		| ClassType
		| TrainingManufacturer[]
		| undefined;
}

export interface TrainingManufacturer {
	extensionId: number;
	manufacturer: string;
	manufacturerAlternativeName: string;
}

export interface TrainingClassDate {
	category: string;
	classId: string;
	dateId: string;
	id?: string;
	instructorName: string;
	manufacturerName: string;
	public?: string;
	seatsTaken?: string;
	startDate: string;
	title: string | "";
	trainingPeriod?: string;
}

export interface TrainingDateActivityLogItem {
	adminUid: string;
	adminUserFullname: string;
	affectedUid: string;
	affectedUserFullname: string;
	description: string;
	doerUid: string;
	doerUserFullname: string;
	id: string;
	refId: string;
	type: string;
	when: string;
}

export interface TrainingDateParticipant {
	email: string;
	fullname: string;
	idcec: string;
	invoiceId: string;
	invoiceLink: string;
	invoiceStatus: string;
	participantId: string;
	signedUp: string;
}

export interface TrainingSubcategory {
	id: number;
	classType: ClassType;
	classes: TrainingClassDetail[];
	description: string;
	name: string;
	sequence: number | null;
}

export interface TrainingCategory {
	description: string;
	name: string;
	subcategories: TrainingSubcategory[];
}

export interface CETAndSpecClassesResponse {
	cetCertification: TrainingSubcategory[];
	scheduledTraining: TrainingSubcategory[];
	selfPacedTraining: TrainingSubcategory[];
	liveWebinarsCetAndSpec: TrainingSubcategory[];
	selfPacedWebinarsCetAndSpec: TrainingSubcategory[];
}

export interface ProjectMatrixClassesResponse {
	categoryWebinar: TrainingSubcategory[];
	categoryScheduledTraining: TrainingSubcategory[];
	categorySelfPaced: TrainingSubcategory[];
}

export interface CETDeveloperClassesResponse {
	developerCertification: TrainingSubcategory[];
	scheduledTrainingDeveloper: TrainingSubcategory[];
	liveWebinarsDeveloper: TrainingSubcategory[];
}

export interface CataloguesClassesResponse {
	liveWebinarsCatalogues: TrainingSubcategory[];
	scheduledTrainingCatalogues: TrainingSubcategory[];
}

export interface QAClassesResponse {
	liveWebinarsQa: TrainingSubcategory[];
	scheduledTrainingQa: TrainingSubcategory[];
	certificationQA: TrainingSubcategory[];
}

export interface ServiceAndDevelopmentClassesResponse {
	serviceAndDevelopmentPartners: TrainingSubcategory[];
}

export interface AcademyClassesResponse {
	subcategories: TrainingSubcategory[];
}

interface ClassDetailsResponse {
	class: TrainingClassDetail;
	defaultEmail?: string;
}

export interface TrainingDateActivityLogsResponse {
	data: TrainingDateActivityLogItem[];
	trainingClass: TrainingClassDetail;
	trainingDate: TrainingClassDate;
}

export interface TrainingDateParticipantsResponse {
	data: TrainingDateParticipant[];
	trainingClass: TrainingClassDetail;
	trainingDate: TrainingClassDate;
}

export interface TrainingClassListResponse {
	data: TrainingClassDate[];
}

export interface TrainingClassResponse {
	data: TrainingClassDate[];
	trainingClass: TrainingClassDetail;
}

export interface Participant {
	email: string;
	idcec: string;
	uuid: string;
}

export interface SummaryItem {
	item: string;
	price: string;
	quantity: number;
	discount: string;
	total: string;
}

export interface ConfirmData {
	summary: SummaryItem;
	newPrice?: string;
	refkey: string;
	type: "free" | "checkout" | "finalized";
}

export interface ExtensionOption {
	extension_id: number;
	name: string;
	parent_to_cet?: boolean;
}

export interface ProductOption {
	product_id: number;
	name: string;
}

export interface EditionInfo extends ExtensionInfo {
	extensionProducts: EditionProduct[];
	bundledExtensions: ExtensionInfo[];
}

export interface EditionResponse extends SuccessResponse {
	edition: EditionInfo;
}

export interface EditionFormDataResponse {
	extensions: ExtensionOption[];
	products: ProductOption[];
}

export interface ExtensionsResponse {
	data: ExtensionOption[];
}

export interface ExtensionInfoResponse {
	extensions: ExtensionInfo[];
}

export interface ExtensionInfo {
	color?: string;
	extensionId: number;
	gitManufacturer: number;
	gitManufacturerLink: string;
	gitProject: number;
	gitProjectLink: string;
	gitlab: ExtensionGitLab;
	id: number;
	imageS3: number;
	imageUrl: string;
	industryDescription?: string;
	isPartner: boolean;
	logo: string;
	manufacturerName: string;
	name: string;
	pkg: string;
	supportType: string;
	url: string;
}

export interface ExtensionGitLab {
	extensionId: number;
	gitlabDeveloperGroupId: number;
	gitlabMaintainerGroupId: number;
	gitlabProjectGroupId: number;
	isManual: boolean;
}

export interface ListEditionsResponse {
	editions: EditionInfo[];
}

export interface CustomerMigrationsResponse {
	requireMigration: boolean;
	options: types.EditionLW[];
}

export interface ListAPIKeysResponse {
	keys: siteAdminTypes.APIKey[];
}
export interface UserLicenseInfo {
	type: string;
	extra?: string;
}
export interface License extends types.LicenseLW {
	requestStatus: string;
	formattedValidTo: string;
	paid: boolean;
	expiring?: boolean;
	info: UserLicenseInfo[];
	locked: boolean;
	offline: boolean;
	subLicenses?: License[];
	isSupervisor?: boolean;
}

export interface LicenseCountResponse {
	licenses_count: number;
}

export interface LicenseResponse {
	licenses: License[];
}

export interface GroupWithDifferentExpiredDates {
	company: string;
	expiration_date: number[];
	group_id: number;
	name: string;
	uid: string;
	[key: string]: string | number | number[];
}

export interface ListGroupWithDifferentExpiredDatesResponse {
	data: GroupWithDifferentExpiredDates[];
}

export interface ListExtensionWithDifferentExpiredDatesResponse {
	data: ExtensionWithDifferentExpiredDates;
}

export interface ExtensionWithDifferentExpiredDates {
	admins: UserLW[];
	extensions: Extension[];
}

export interface Extension {
	cnt: number;
	extension_id: number;
	extension_name: string;
	valid_to: string;
}

export interface DeleteAPIKeysResponse {
	data: string;
}

export interface GettingStartedInfoResponse {
	isConfigura: boolean;
	resource: string;
}

export interface RoleResponse {
	role: Role;
}

export interface RolesResponse {
	roles: Role[];
}

export interface Role {
	id: number;
	title: string;
	description: string;
	count: number;
	countExtension: number;
}

export interface Royalty {
	amountTransfered: string;
	extensionName: string;
	id: number;
	paidFrom: string;
	paidTo: string;
}

interface RoyaltyResponse {
	agreement: Agreement;
	royalty: Royalty[];
}

export interface RoyaltyBankInfo {
	accountIban?: string;
	address?: string;
	bankAddress?: string;
	bankCity?: string;
	bankCode?: string;
	bankCountry?: string;
	bankId?: string;
	bankName?: string;
	bankPostal?: string;
	bankState?: string;
	bicSwift?: string;
	city?: string;
	country?: string;
	currency?: string;
	firstname?: string;
	id?: string;
	lastname?: string;
	manufacturerId?: string;
	postal?: string;
	state?: string;
}

export interface BankCodeInfo {
	bank: string;
	code: string;
	digits: number;
}

interface RoyaltyBankResponse {
	agreement: Agreement;
	bankCode: BankCodeInfo[] | null;
	manufacturer: ManufacturerDataPropsMW;
	royaltyBank: RoyaltyBankInfo | null;
}

export interface SentryOrganization {
	name: string;
	slug: string;
}

interface SentryOrganizationsResponse {
	sentryOrganizations: SentryOrganization[];
}

export type SpeakerType = "keynote" | "power_user" | "configura" | "sponsor";

export interface Speaker {
	id: number;
	name: string;
}

export interface SessionSlot {
	created: number;
	end_time: number;
	event_session_id: number;
	id: number;
	location: string;
	modified: number;
	registration_alternative_id?: number;
	start_time: number;
}

export interface Session {
	description: string;
	pdf_url?: string;
	speaker_ids: number[];
	tags: EventTag[] | null;
	title: string;
	video_link: string | null;
}

export interface ExtensibleLicense {
	allocated_to_email: string;
	license_id: number;
	license_type_name: string;
	linked_to_id: number;
	valid_from: string;
	valid_to: string;
}

export interface ExtensibleLicensesResponse {
	data: ExtensibleLicense[];
	name: string;
}

export interface UserResult {
	email: string;
	firstname: string;
	lastname: string;
	phone: string;
	picture_s3?: string;
	uid: number;
	user_group_id: number;
}

export interface ManufacturerResult {
	id: number;
	name: string;
	picture_s3: string;
}

export interface ExtensionResult {
	id: number;
	name: string;
	picture_s3: string;
}

export interface SearchAccountResponse {
	manufacturers: ManufacturerResult[];
	users: UserResult[];
	extensions: ExtensionResult[];
}

export interface FindCustomerAdminResponse {
	customer: types.CustomerLW;
}

interface ManufacturerAgreements {
	agreements: ManufacturerAgreement[];
	manufacturer: ManufacturerDataPropsMW;
}

interface AllTypeAgreementsResponse {
	manufacturerAgreements: ManufacturerAgreement[];
	extensionAgreements: ExtensionAgreement[];
	userAgreements: types.Agreement[];
	customerAgreements: CustomerAgreement[];
}

interface AgreementTitle {
	id: number;
	title: string;
}

interface AgreementTitlesResponse {
	agreements: AgreementTitle[];
}

interface ExtensionAgreementResponse {
	agreement: ExtensionAgreement;
}

interface ExtensionAgreements {
	agreements: ExtensionAgreement[];
	extension: ExtensionTypeHW;
}

interface ExtensionMarketplacePortfoliosResponse {
	portfolios: ExtensionPortfolio[];
	extension: types.ExtensionLW;
	hasLicense: boolean;
}

interface ExtensionPortfolioResponse {
	portfolios: PortfolioLW[];
}

interface UserAgreements {
	manufacturerAgreements: ManufacturerAgreement[];
	user: UserLW;
	userAgreements: types.Agreement[];
}

export interface ManufacturerAgreement extends types.Agreement {
	manufacturerId: number;
	manufacturerName: string;
}

export interface ExtensionAgreement extends types.Agreement {
	extensionId: number;
	extensionName: string;
	supportType: "basic" | "superior";
	terminatedAt: number;
}

export interface CronJob {
	description: string;
	execInterval: string;
	execWindow: string;
	id: string;
	isOn: boolean;
	isRunning: boolean;
	lastExecTime: string;
	lastFinish: string;
	lastLog: string;
	lastStart: string;
	name: string;
	runningTime: string;
	tags: string;
	watch: boolean;
}

export interface CronJobsResponse {
	cronjobs: CronJob[];
}

export interface CronJobActionResponse {
	cronjob: CronJob;
	success: string;
}

export interface TranslationsResponse {
	data: Translations;
	handlebars: { [state: string]: boolean };
}
export interface TranslationScopesResponse {
	scopes: string[];
}

export interface TranslationsByScopeResponse {
	list: TranslationObject[];
}

export interface TranslationsImportPreviewResponse {
	data: {
		affectedScopes: string[];
		affectedTranslations: string[];
	};
}

export interface TranslationObject {
	de: string;
	en: string;
	id: number;
	ja: string;
	scope: string;
	string: string;
	zh: string;
	handlebars_enabled: "string";

	[key: string]: string | number;
}

export interface TranslationResponse {
	translation: TranslationObject;
}

export interface DevListAdminsResponse {
	data: any;
}

interface SentryProjectOption {
	title: string;
	value: string;
}

export interface MoveCrashData {
	canRedirectCrash: boolean;
	created: number;
	fingerprint: string;
	projectOptions: SentryProjectOption[];
	sentry_id: string;
}

interface CrashDetailsResponse {
	data: MoveCrashData;
}

export interface Resources {
	agreement: Resource[];
	annualReport: Resource[];
	caseStudy: Resource[];
	developerCaseStudy: Resource[];
	document: Resource[];
	educationCaseStudy: Resource[];
	userCaseStudy: Resource[];
	videoCaseStudy: Resource[];
	whitePaper: Resource[];
}

export interface ResourcesResponse {
	resources: Resources;
	isConfigura: boolean;
}

export interface Resource {
	description: string;
	file_url: Option<string>;
	link_url: Option<string>;
	id: number;
	image_url: string;
	key: string;
	order: string;
	phoenix: number;
	title: string;
	type: string;
}

interface StageServicesPrices {
	apiPriceRender: number;
	apiPriceExport: number;
	apiPriceBase: number;
	annualPriceRender: number;
	annualPriceExport: number;
	annualPriceBase: number;
}

export interface StageServices {
	base: number;
	created: number;
	developmentPartner: number;
	evaluating: number;
	export: number;
	internal: number;
	manufacturerId: number;
	modified: number;
	monthlyCostThreshold: number | undefined;
	prices: StageServicesPrices;
	render: number;
}

export interface CatalogueStatus {
	CETCLAgreementURL: string;
	CWALTAgreementURL: string;
	agreements: ExtensionAgreement[];
	hasCatalogues: boolean;
	hasCataloguesInStage: boolean;
	hasStage: boolean;
	stageServices: StageServices | null;
}

export interface CatalogueLanguagesResponse {
	languages: CatalogueLanguage[];
}

export interface CatalogueLanguage {
	cid: number;
	name: string;
	description: string;
	prdCat: string;
	prdCatVersion: string;
}

export interface StagePartners {
	manufacturers: ManufacturerDataPropsMW[];
	services: StageServices[];
}

export interface ManufacturerDataUsageProps extends ManufacturerDataPropsMW {
	project_ultimate: number;
	filespace_cat: number;
	filespace_ext: number;
	catalogue_pay_status: string;
	catalogue_pay_status_comment: string;
	monthly_cost: number;
	yearly_cost: number;
	oldest_created_cat: number;
	block_catalogues: boolean;
}

export interface ManufacturersFilespaceUsageResponse {
	manufacturers: Array<ManufacturerDataUsageProps>;
}

export interface Translation {
	de?: string;
	en?: string;
	id?: string;
	ja?: string;
	scope: string;
	string: string;
	zh?: string;
}

export interface SentryURLResponse {
	url: string;
}

export interface Price {
	currencyPrice: CurrencyPrice;
	id: number;
	plan: "month" | "year" | "halfyear" | "quarter" | "fixedyear";
}

export interface CurrencyPrice {
	[key: string]: number;
}

export interface PriceLog extends Omit<Log, "refId"> {
	affected_customer_id: number | null;
	model_id: number | null;
	set_id: number | null;
}

export interface PriceDiscount {
	discount: number;
	id: number;
	modelId: number;
	quantity: number;
}

export interface PriceDiscountExtension extends PriceDiscount {
	extensionId: number;
}

export interface PriceModelPerPlan {
	[key: string]: PriceModel[];
}

export interface PriceModel {
	customers: types.CustomerLW[];
	discounts: PriceDiscount[];
	endTime: number | undefined;
	grandfathered: boolean;
	id: number;
	isDefault: boolean;
	prices: Price;
	productId: number;
	setId: number;
	startTime: number;
	useIndex: boolean;
}

export interface PriceSet {
	priceModels: PriceModel[];
	setId: number;
	isDefault: number;
	grandfathered: number;
}

export interface PriceSetsResponse {
	prices: PriceSet[];
}

export interface PriceLogsResponse {
	logs: PriceLog[];
}

export interface PriceSetResponse {
	price: PriceSet;
}

export interface PriceModelResponse {
	price: PriceModel;
}

export interface PriceModelsResponse {
	priceModels: PriceModel[];
}

export interface GrandfatheredPriceModelsResponse extends PriceModelsResponse {
	product_name: string;
}

export interface PriceDiscountsExtensionResponse {
	discounts: PriceDiscountExtension[];
}

export interface CustomerAgreement extends Agreement {
	customerId: number;
	customerName: string;
}

export interface CustomerAgreementsResponse {
	agreements: CustomerAgreement[];
}

export interface CustomerLog extends Log {
	customer_id: number | null;
}

export interface CustomerLogsResponse {
	logs: CustomerLog[];
}

export interface CustomerOfflineListResponse {
	users: OfflineLicenseUser[];
	unlockCount: number;
}

export interface CustomerCountUnpaidInvoicesResponse {
	count: number;
}

export type TwinmotionPurchaseType = "form_pending" | "form_submitted" | "voided";

export interface CustomerTwinmotionPurchaseResponse {
	purchaseId: number;
	invoiceId: number;
	status: TwinmotionPurchaseType;
}
export interface Address {
	address1: string;
	address2: string;
	city: string;
	company: string;
	country: string;
	id: number;
	name: string;
	postalCode: string;
	state: string;
}

export type CustomerType = "personal" | "business" | "manufacturer";

export interface Customer extends types.CustomerLW {
	address: Address;
	allowOffline: boolean;
	allowLicensePool: boolean;
	created: number;
	currency: string;
	newCLA: number;
	type: CustomerType;
	daysUntilPayment: number;
	editionId: number;
	emailPdfInvoice: boolean;
	escrow?: Escrow;
	invoiceEmail: string;
	invoiceExtra: string;
	invoicePo: number;
	licensesExpire: number;
	modified: number;
	parentId: number;
	vatNo: number;
}

export interface CustomersResponse {
	customers: Customer[];
}

export interface CustomerResponse {
	customer: Customer;
}

export interface CustomerIdResponse {
	customerId: number;
}

export interface Currency {
	code: string;
	symbol: string;
	active: number;
}

export interface Escrow {
	projectNo: string;
	projectName: string;
	customerNo: string;
	daysUntilPayment: string;
}

// JIRA
interface JiraUser {
	active: boolean;
	displayName: string;
	emailAddress: string;
	key: string;
	name: string;
	self: string;
	timeZone: string;
}

interface JiraIssueType {
	avatarId: number;
	description: string;
	iconUrl: string;
	id: string;
	name: string;
	self: string;
	subtask: boolean;
}

interface JiraIssuePriority {
	iconUrl: string;
	id: string;
	name: string;
	self: string;
}

export type ReleaseClient = "no testing" | "major" | "minor";
export interface Build {
	id: number;
	active: boolean;
	version: string;
	releaseClient: ReleaseClient;
	distributionName: string;
	release?: ManufacturerRelease;
	versionNumberBreakdown: string[];
	created: number;
}

export interface ReleaseStatisticManufacturers {
	signed: SignedReleaseManufacturer[];
	unsigned: UnsignedReleaseManufacturer[];
}

export interface ManufacturerRelease {
	version_id: number;
	manufacturerId: number;
	branchName: string;
	tested: boolean;
	releaseDate: number | undefined;
	created: number;
	modified: number | undefined;
	shareInformation: boolean;
}

export interface UnsignedManufacturersResponse {
	unsignedManufacturers: UnsignedManufacturer[];
	canBulkEdit: boolean;
	correspondingOfficialVersion?: string;
	correspondingOfficialVersionId?: number;
}

export interface UnsignedManufacturer {
	name: string;
	id: number;
	extensions: [ExtensionReleaseStatus];
	manufacturerReleaseStatus: ManufacturerReleaseStatus;
}

export interface ExtensionReleaseStatus extends ManufacturerReleaseStatus {
	name: string;
	id: number;
}

export type DeactivateStatus = "pending" | "deactivated" | undefined;
export type ForceUninstallStatus = "pending" | "uninstalled" | undefined;

export interface ManufacturerReleaseStatus {
	deactivate: boolean;
	unblockUpdate: boolean;
	forceUninstall: boolean;
	bulkDeactivateStatus: DeactivateStatus;
	bulkForceUninstallStatus: ForceUninstallStatus;
}

export interface DistributionsResponse {
	distributions: Distribution[];
	canBulkEdit?: boolean;
}

export interface MigrationCandidatesResponse {
	migrationCandidates: MigrationCandidate[];
}

export interface ReleaseVersionsResponse {
	releaseVersions: string[];
	canBulkEdit: boolean;
}

export interface Distribution {
	distributionName: string;
	distributionId: number;
	builds: Build[];
}

export interface MigrationCandidate {
	id: number;
	version: string;
	releaseVersion: string;
	active: boolean;
	signed: boolean;
}

export interface ManufacturerReleaseResponse {
	build: Build;
	manufacturerRelease?: ManufacturerRelease;
	extensions: ManufacturerReleaseExtension[];
	showForm: boolean;
}

export interface ManufacturerReleaseExtension extends ExtensionTypeHW {
	addedToVersion: boolean;
}

export interface ManufacturerBySignedRelease {
	releaseClient: ReleaseClient;
	signedReleaseManufacturers: SignedReleaseManufacturer[];
	unsignedReleaseManufacturers: UnsignedReleaseManufacturer[];
	correspondingOfficialVersion?: string;
	correspondingOfficialVersionId?: number;
}

export interface ExtensionsInManufacturerRelease {
	extensionName: string;
	extensionId: number;
	installed: InstalledCount;
	isInOfficial: boolean;
}

export interface UnsignedReleaseManufacturer {
	manufacturerId: number;
	manufacturerName: string;
	installed: InstalledCount;
	extensions?: ExtensionsInManufacturerRelease[];
}

export interface SignedReleaseManufacturer extends ManufacturerRelease {
	releaseDate: number;
	manufacturerName: string;
	signedBy: string;
	version: string;
	installed: InstalledCount;
	extensions?: ExtensionsInManufacturerRelease[];
	isInOfficial?: boolean;
}

export interface ManufacturerReleasedExtensions {
	extensionName: string;
	extensionId: number;
	installed: InstalledCount;
	isInOfficial: boolean;
}

export interface InstalledCount {
	humanCount: string;
	count: number;
}

interface JiraProject {
	id: string;
	key: string;
	name: string;
	projectCategory: {
		description: string;
		id: string;
		name: string;
		self: string;
	};
	projectTypeKey: string;
	self: string;
}

interface JiraIssueStatus {
	description: string;
	iconUrl: string;
	id: string;
	name: string;
	self: string;
	statusCategory: {
		colorName: string;
		id: number;
		key: string;
		name: string;
		self: string;
	};
}

export interface JiraIssue {
	id: string;
	key: string;
	self: string;
	fields: {
		assignee: JiraUser;
		creator: JiraUser;
		customfield_10300: JiraUser; // Developer
		customfield_11700: string; // Architect
		customfield_11701: string; // QA Lead
		customfield_11800: {
			displayName: string;
		}; // Project manager
		description: string;
		issuetype: JiraIssueType;
		priority: JiraIssuePriority;
		project: JiraProject;
		reporter: JiraUser;
		status: JiraIssueStatus;
		summary: string;
	};
}

export interface JiraIssuesListResponse {
	issues: JiraIssue[];
}

export interface ProductsResponse {
	products: Product[];
}

export interface MarketplaceManufacturer {
	manufacturerName: string;
	editions: EditionTypeMap<string[]>;
}

export type MarketplaceEditionType =
	| "CET Commercial Interiors"
	| "CET Kitchen & Bath"
	| "CET Material Handling"
	| "CET Material Handling Gonvarri Edition"
	| "Spec"
	| "Workspace"
	| "Notify";

export type EditionTypeMap<T> = { [key in MarketplaceEditionType]?: T };

export type MarketplaceManufacturerMap = { [manufacturerId: string]: MarketplaceManufacturer };

export interface MarketplaceManufacturerListResponse {
	manufacturers: MarketplaceManufacturerMap;
	editions: EditionTypeMap<string>;
	unlinkedPmData: PmDataLW[];
}

export interface MarketplaceProcessCheckoutResponse {
	paid: boolean;
	redirectUrl: string;
	timeoutUrl: string;
}

export interface ListPMDataResponse {
	manufacturers: ManufacturerLW[];
	pm: PmDataLW[];
	links: PmDataLinks[];
}

export interface AddedSubscriptionProduct {
	customer_id: number;
	pid: number;
	name: string;
	quantity: number;
	plan: string;
	validTo: number;
	status?: subscriptionTypes.SubscriptionStatus;
}

export interface SubscriptionsResponse {
	subscriptions: subscriptionTypes.Subscription[];
	plans: subscriptionTypes.SubscriptionPlan[];
	hasStripeConnection: boolean;
}

export interface SubscriptionLicensesResponse {
	licenses: types.CETLicenseLW[];
	subscriptions: AddedSubscriptionProduct[];
}

export interface SubscriptionsPaymentMethodsResponse {
	paymentMethods: Array<{
		id: string;
		brand: string;
		displayBrand: string;
		cardIconUrl: string;
		last4: string;
		displayExp: string;
		isExpired: boolean;
	}>;
}

export interface SummaryCartResponse {
	cart: subscriptionTypes.SummaryCart;
}

export interface CustomerLicensesDataResponse {
	activeUsers: ActiveLicenseUser[];
	requestedEditionId: number;
	editions: types.EditionLW[];
	extensions: ExtensionType[];
	subscriptionSets: SubscriptionSet[];
}

export interface LicensesExportResponse {
	url: string;
}

export interface ExtensionLicenses {
	extension_id: number;
	extension_name: string;
	icon_url: string;
	isSupervisor: number;
	total: number;
	subLicenses?: {
		extension_id: number;
		extension_name: string;
	}[];
}

export interface SubscriptionSet {
	expiration_date: string;
	edition_id: number;
	formatted_expiration_date: string;
	extensions: ExtensionLicenses[];
	ownedExtensions: ExtensionLicenses[];
}

export interface LicensesOfflinePreviewResponse {
	users: ImportLicenseUser[];
	validUsers: OfflineLicenseUser[];
	hasErrors?: boolean;
}

export interface LicensesOfflineUsersResponse {
	users: OfflineLicenseUser[];
}

export interface OfflineLicenseUser {
	userId: number;
	email: string;
	hardware: string;
	computerName: string;
	created?: number;
}

export interface ImportLicenseUser {
	email: string;
	hardware: string;
	computer_name: string;
	error?: "mismatch_hardware" | "no_license" | "missing_fields" | "hardware_in_used";
	reExport?: boolean;
}

export interface LicenseGroup {
	id: number;
	parentId: number;
	customerId: number;
	name: string;
	admins: UserLW[];
	allowOffline: boolean;
	allowLicensePool: boolean;
	isTopMostGroup: boolean;
	unpaidInvoiceCount?: number;
	licenseStatistics: LicenseStatistics;
}

interface LicenseStatistics {
	count: LicensesCount;
	byExtensions: {
		[key: number]: LicensesCount;
	};
}

export interface LicensesCount {
	totalAllocatedCumulative: number;
	totalUnallocatedCumulative: number;
	totalAllocated: number;
	totalUnallocated: number;
}

export interface FileResponse {
	file: string;
}

export interface AddEditionResponse extends SuccessResponse {
	edition: EditionInfo;
}
export interface GroupAdminsResponse {
	admins: UserLW[];
}
export interface GroupCustomerResponse {
	customers: Customer[];
}

export interface GroupAdminPermissionsResponse {
	groupPermissions: number;
	rootPermissions: number;
}

export interface LicenseLineage {
	licenseGroup: Array<LicenseGroup>;
	allExtensions: Array<types.ExtensionHW>;
}

export interface LicenseGroupsResponse {
	lineages: Array<LicenseLineage>;
}

export interface LicenseOverviewResponse {
	byGroup: LicenseStatistics;
	extensionsData: Array<types.ExtensionHW>;
}

// Build Center

export type BuildCenterTabName =
	| "distributions"
	| "history"
	| "pkgstats"
	| "compare"
	| "release"
	| "chinamsis"
	| "chinasync";

export type BuildCenterReleaseClient = "no_testing" | "major";

export interface BuildCenterConfigurationResponse {
	tabs: BuildCenterTabName[];
}

export interface BuildCenterDistributionsResponse {
	distributions: BuildCenterDistributionRow[];
}

export interface BuildCenterDistributionResponse {
	distribution: BuildCenterDistribution;
}

export interface BuildCenterUpdateDistributionResponse extends BuildCenterDistributionResponse {
	success: Translatable;
}

export interface BuildCenterVersionResponse {
	version: BuildCenterVersion;
}

export interface BuildCenterUpdateVersionResponse extends BuildCenterVersionResponse {
	versionCreated?: BuildCenterVersion;
	success: Translatable;
}

export interface BuildCenterDistributionExtensionsResponse {
	permissibleExtensions: { id: number; name: string }[];
}

export interface BuildCenterInheritablesResponse {
	inheritables: BuildCenterInheritableDistribution[];
}

export interface BuildCenterDistributionMaintainersResponse {
	maintainers: string[];
}

export interface BuildCenterBuildsResponse {
	builds: BuildCenterBuild[];
}

export interface BuildCenterAddBuildsResponse {
	version: BuildCenterVersion;
	versionCreated?: BuildCenterVersion;
	builds: BuildCenterBuild[];
	success: Translatable;
}

export interface BuildCenterAddFirstAidsResponse {
	builds: BuildCenterBuild[] | undefined;
	versionCreated?: BuildCenterVersion;
	success: Translatable;
}

export interface BuildCenterVersionComparisonResponse {
	changes: BuildCenterBuildComparison[];
	newVersionString?: string;
	isMajor?: boolean;
}

export interface BuildCenterFilesResponse {
	files: BuildCenterFile[];
}

export interface BuildCenterDistributionConstructorResponse {
	nameSuffix: string;
	permissibleExtensions: { id: number; name: string }[];
	permissions: BuildCenterDistributionPermissions;
}

export interface BuildCenterDeleteBuildFileResponse {
	success: Translatable;
	files: BuildCenterFile[];
	buildCreated?: BuildCenterBuild;
}

export interface BuildCenterBuildResponse {
	build: BuildCenterBuild;
}

export interface BuildCenterUpdateBuildResponse extends BuildCenterBuildResponse {
	success: Translatable;
}

export interface BuildCenterFileResponse {
	file: BuildCenterFile;
}

export interface BuildCenterUpdateFileResponse extends BuildCenterFileResponse {
	success: Translatable;
}

export interface BuildCenterDeleteBuildResponse {
	success: Translatable;
	versionCreated?: BuildCenterVersion;
}

export interface BuildCenterDistributionRow {
	id: number;
	name: string;
	public: boolean;
	beta: boolean;
	visibleAsOfficial: boolean;
	restricted: boolean;
	favorite: boolean;
	subscribe: boolean;
	hasSelfRedirect: boolean;
	activeVersion?: BuildCenterVersionInfo;
	releasingVersion?: BuildCenterVersionInfo;
	owner: string;
	downloadUrls: BuildCenterDownloadLink[];
}

export interface BuildCenterDistribution extends BuildCenterDistributionRow {
	description: string;
	installKey: string;
	sync: boolean;
	versions: BuildCenterVersionRow[];
	permissions: BuildCenterDistributionPermissions;
	allowedExtensions: AllowedExtension[];
	maintainers: string[];
}

export interface AllowedExtension {
	extensionId: number;
	extensionName: string;
}

export interface BuildCenterVersionInfo {
	id: number;
	version: string;
	active: boolean;
	release: boolean;
	revision: number;
	downloadUrls: BuildCenterDownloadLink[];
	revisions: BuildCenterVersionRevision[];
	hasActiveRevision: boolean;
}

export interface BuildCenterVersionRow extends BuildCenterVersionInfo {
	selfRedirected: boolean;
}

export interface BuildCenterVersion extends BuildCenterVersionRow {
	distribution: {
		id: number;
		name: string;
	};
	inherit?: {
		id: number;
		distributionId: number;
		version: string;
		revision: number;
		distributionName: string;
	};
	permissions: BuildCenterVersionPermission;
	releaseClient: BuildCenterReleaseClient;
	releaseVersion: string;
	rebuildingMsi: boolean;
	hasSignedManufacturerReleases: boolean;
	syncToInheritedVersion: string | null;
}

export interface BuildCenterVersionRevision {
	versionId: number;
	revision: number;
	created: number;
	active: boolean;
	downloadUrls: BuildCenterDownloadLink[];
}

export interface BuildCenterVersionPermission {
	release: boolean;
	selfRedirect: boolean;
	toggleActive: boolean;
	editBuilds: boolean;
	edit: boolean;
	editInheritance: boolean;
	editReleaseClient: boolean;
	downgrade: boolean;
	createRevision: boolean;
	syncToInherited: boolean;
	excludeExtensionBuilds: boolean;
}

export interface BuildCenterInheritableDistribution {
	id: number;
	name: string;
	beta: boolean;
	versions: { id: number; version: string }[];
}

export interface BuildCenterDistributionPermissions {
	edit: boolean;
	synchronize: boolean;
	selfRedirect: boolean;
	toggleFavorite: boolean;
	editName: boolean;
	editBuilds: boolean;
	editVisibility: boolean;
	editExtensionVisibility: boolean;
	editOfficialVisibility: boolean;
	delete: boolean;
	createRevision: boolean;
	editMaintainers: boolean;
}

export interface BuildCenterDistributionMaintainer {
	distributionId: number;
	email: string;
}

export interface BuildCenterBuild {
	id: number;
	name: string;
	extensionId: number;
	extensionName: string;
	created: number;
	permissions: BuildCenterBuildPermissions;
}

export type BuildCenterComparisonStatus = "update";

export interface BuildCenterBuildComparison {
	build: string;
	extension: string;
	status: BuildCenterComparisonStatus;
	version: string;
	files: BuildCenterFileComparison[];
}

export interface RedirectLicenseGroup {
	admins: UserLW[];
	customerId: number;
	id: number;
	name: string;
}

export interface RedirectVersion {
	active: boolean;
	beta: boolean;
	created: number;
	distributionId: number;
	distributionName: string;
	id: number;
	releaseClient: ReleaseClient;
	revision: number;
	version: string;
}

export interface BuildCenterRedirect {
	affectedUser?: UserLW;
	comment: string;
	created: number;
	createdBy: UserLW;
	distributionId: number;
	distributionName: string;
	extension?: ExtensionTypeHW;
	group?: RedirectLicenseGroup;
	rule: "extension" | "group" | "user" | "version";
	expiryDate: string;
	expiryUnix: number;
	set: number;
	version?: RedirectVersion;
	versionId: number;
	versionName: string;
}

export interface BuildCenterRedirectData {
	[key: string]: {
		extension: BuildCenterRedirect[];
		group: BuildCenterRedirect[];
		selfRedirected: boolean;
		user: BuildCenterRedirect[];
		version: BuildCenterRedirect[];
		versionName: string;
	};
}

export interface BuildCenterRedirectResponse {
	extensions: ExtensionTypeHW[];
	nonRedirectedVersions: RedirectVersion[];
	redirects: BuildCenterRedirectData;
	versions: RedirectVersion[];
}

export interface BuildCenterVersionRedirectResponse {
	nonRedirectedVersions: RedirectVersion[];
	redirects: BuildCenterRedirectData;
	success: string;
	versions: RedirectVersion[];
}

export interface BuildCenterVersionRedirectFormResponse {
	redirects: BuildCenterRedirectData;
	success: string;
	usersAdded: string[];
	usersMissing: string[];
}

export interface BuildCenterFileComparison {
	pkg: string;
	status: BuildCenterComparisonStatus;
	filesize: string;
	from: string;
	to: string;
}

export interface BuildCenterDownloadLink {
	locationId: FileLocation;
	url: string;
	urlDynamic: Option<string>;
}

export interface BuildCenterBuildPermissions {
	addToOfficial: boolean;
	addToCustom: boolean;
	edit: boolean;
	editFile: boolean;
	delete: boolean;
	deleteFile: boolean;
}

export type BuildCenterSeverity = "minor" | "major" | "critical";

export type BuildCenterUpdateOption =
	| "minor_bugFix"
	| "major_bugFix"
	| "critical_bugFix"
	| "minor_newFeature"
	| "major_newFeature"
	| "minor_contentUpdate"
	| "major_contentUpdate";

export interface BuildCenterFile {
	id: number;
	name: string;
	pkg: string;
	size: number;
	version: string;
	updateOption: BuildCenterUpdateOption | null;
	releaseNoteUrl: string | null;
	dueDate: number | null;
	dependencies?: BuildCenterFileDependency[];
}

export interface BuildCenterFileDependency {
	pkg: string;
	version?: string;
	using?: string;
}

interface FrameTokenResponse {
	token: string;
	code: string;
}

export interface StripeCheckoutSessionResponse {
	status: "complete" | "expired" | "open";
}
export interface StripeCreateCheckoutSessionResponse {
	url: string;
}

interface StreamUrlResponse {
	url: string;
}

export interface DemoUserSettingsResponse {
	experimental: boolean;
}

interface ExtensionFile {
	etag: string;
	id: number;
	modified: number;
	remote_file_path: string;
	size: number;
	status: string;
	url: string;
	version_id: string;
}

export interface DemoDrawingsResponse {
	files: {
		data: ExtensionFile[];
		object: string;
	};
}

export interface MultichannelDemoOptionResponse {
	options: types.MultichannelDemoOption[];
}

export interface MultichannelDemoStackResponse {
	stack: types.StackRegion;
}

export interface DemoUpdateDrawingResponse extends DemoDrawingsResponse {
	newIDs: number[];
	oldIDs: number[];
}

export interface GiveLicenseUserStatus {
	name: string;
	givenLicenses: string[];
	failedExtensions: string[];
	ownedExtensions: string[];
}

// Security - Two Factor Authentication
export interface TFAAuthentication {
	uid: number;
	createdAt: number;

	authenticated?: boolean;
	attempts?: number;

	hasCodes?: boolean;
	hasRegister?: boolean;

	isLocked?: boolean;
}

export interface TFAAuthenticationCode {
	qrCode: string;
	uriCode: string;
}

export type TFAAuthenticationResponse = {
	authentication: Option<TFAAuthentication>;
};

export interface TFAAuthenticationsResponse {
	authentications: Option<TFAAuthentication[]>;
}

export interface TFAAuthenticationCodeResponse {
	code: Option<TFAAuthenticationCode>;
}

export interface TFAEvaluationResponse {
	evaluation: {
		evaluated: boolean;
		period: number;
		tolerence: number;
	};
}

export interface TFARegisterResponse {
	register: {
		registered: boolean;
	};
}

export interface ListTasksResponse {
	topics: Array<TaskTopic>;
	tasks: siteAdminTypes.Task[];
}

export interface TaskTopic {
	id: number;
	schema: string;
	topic: string;
}

export interface TaskResultResponse {
	result: string;
}

export interface CacheKeyData {
	key: string;
	type: string;
	keyParts: Array<string>;
}

export interface CacheKeyValueResponse {
	value: Object;
}

export interface CacheManagementResponse {
	keys: Array<CacheKeyData>;
}
export interface SiteadminDeveloperResponse {
	developers: DeveloperLW[];
}

export interface SiteadminExtensionResponse {
	extensions: types.ExtensionLW[];
}

export interface SiteadminSSOAppResponse {
	app: SSOApp;
}

export interface SiteadminSSOListResponse {
	apps: SSOApp[];
}

export interface SSOApp {
	app_id: string;
	id: number;
	force_sso: number;
	disabled: number;
	domain: string;
}

// AWS Status
export interface JobStatus {
	type: string;
	first_received: string;
	first_received_readable: string;
	length: number;
}

export interface JobErrors {
	id: number;
	received: number;
	type: string;
	error: string;
}

export interface AwsStatus {
	buildSync: JobStatus[];
	catalogueSync: JobStatus[];
	installerSync: JobStatus[];
	upload: JobStatus[];
	errors: JobErrors[];
}

// Search
export interface SearchRequest<T extends keyof SearchTypes> {
	search: string;
	orderBy: string;
	orderDirection: "ASC" | "DESC";
	paginationPage: number;
	paginationNrOfItems: number;
}
export interface SearchResponse<T extends keyof SearchTypes> {
	results: Array<SearchTypes[T]>;
}

export interface SaveSearchRequest {
	type: keyof SearchTypes;
	searchId: number;
}

export interface RecentSearchResponse {
	recentSearches: Array<
		| RecentSearchEntity<"user">
		| RecentSearchEntity<"license">
		| RecentSearchEntity<"invoice">
		| RecentSearchEntity<"customer">
	>;
}

// Interface responses
export interface Responses {
	// ApplicationReport
	"applicationreport/crash-support-request": SuccessResponse;
	"applicationreport/get-crash-details": CrashDetailsResponse;
	"applicationreport/get-sentry-url": SentryURLResponse;

	"auth/login": undefined & ErrorResponse;
	"auth/logout": SuccessResponse;
	"auth/check-email": EmailCheckResponse;
	"auth/stop-masquerading": SuccessResponse;

	// Archived events
	"eventarchives/list": EventsResponse;

	// Briefcases
	"briefcases/add": BriefcaseResponse & SuccessResponse;
	"briefcases/admins/list": BriefcaseListMembersResponse;
	"briefcases/delete": SuccessResponse;
	"briefcases/edit": BriefcaseResponse;
	"briefcases/favorite/add": BriefcaseResponse;
	"briefcases/favorite/remove": BriefcaseResponse;
	"briefcases/files/list": ListBriefcaseFilesResponse;
	"briefcases/get-extension": BriefcaseExtensionResponse;
	"briefcases/invites/accept": SuccessResponse;
	"briefcases/invites/add": BriefcaseInvitesResponse & SuccessResponse;
	"briefcases/invites/add-admin": BriefcaseInvitesResponse & SuccessResponse;
	"briefcases/invites/decline": SuccessResponse;
	"briefcases/invites/list": BriefcaseInvitesResponse;
	"briefcases/invites/list-pending": BriefcaseInvitesPendingResponse;
	"briefcases/invites/remove": BriefcaseInvitesResponse & SuccessResponse;
	"briefcases/leave": SuccessResponse;
	"briefcases/list": BriefcaseListResponse;
	"briefcases/members/export": ExportResponse;
	"briefcases/members/list": BriefcaseListMembersResponse;
	"briefcases/members/remove": SuccessResponse;
	"briefcases/pin-post/add": BriefcasePostResponse;
	"briefcases/pin-post/list": ListBriefcasePostResponse;
	"briefcases/pin-post/remove": BriefcasePostResponse;
	"briefcases/posts/add": BriefcasePostResponse;
	"briefcases/posts/add-like": BriefcasePostLikeResponse;
	"briefcases/posts/edit": BriefcasePostResponse;
	"briefcases/posts/get": BriefcasePostResponse;
	"briefcases/posts/list": ListBriefcasePostResponse;
	"briefcases/posts/list-administered": ListBriefcasePostResponse;
	"briefcases/posts/list-comments": BriefcasePostCommentResponse;
	"briefcases/posts/list-favorite": ListBriefcasePostResponse;
	"briefcases/posts/list-training": ListBriefcasePostResponse;
	"briefcases/posts/remove": BriefcasePostResponse;
	"briefcases/posts/remove-like": BriefcasePostLikeResponse;
	"briefcases/sticky-post/add": BriefcasePostResponse;
	"briefcases/sticky-post/remove": BriefcasePostResponse;
	"briefcases/visit": SuccessResponse;

	// Account
	"account/close": CloseAccountResponse;
	"account/close/abort": AbortCloseAccountResponse;
	"account/close/status": CloseAccountStatusResponse;

	// Academy
	"academy/catalogues-classes": CataloguesClassesResponse;
	"academy/cet-designer-classes": CETAndSpecClassesResponse;
	"academy/project-matrix-classes": ProjectMatrixClassesResponse;
	"academy/cet-developer-classes": CETDeveloperClassesResponse;
	"academy/get-academy-classes": AcademyClassesResponse;
	"academy/get-class-details": ClassDetailsResponse;
	"academy/qa-classes": QAClassesResponse;
	"academy/service-and-development-classes": ServiceAndDevelopmentClassesResponse;

	// Public awards
	"awards/confirm-vote": ConfirmAwardVote;
	"awards/get": GetAwardResponse;
	"awards/get-previous-finalists": GetPreviousAwardFinalistResponse;
	"awards/save-submission": GetAwardSubmissionResponse;
	"awards/save-submission-upload": GetAwardSubmissionResponse;

	// Build center
	"buildcenter/builds/delete": SuccessResponse;
	"buildcenter/builds/delete-file": BuildCenterDeleteBuildFileResponse;
	"buildcenter/builds/list": BuildCenterBuildsResponse;
	"buildcenter/builds/list-files": BuildCenterFilesResponse;
	"buildcenter/builds/export-build-files": { url: string };
	"buildcenter/configuration": BuildCenterConfigurationResponse;
	"buildcenter/distributions/create-new":
		| BuildCenterDistributionConstructorResponse
		| BuildCenterDistributionResponse;
	"buildcenter/distributions/delete": SuccessResponse;
	"buildcenter/distributions/delete-maintainer": BuildCenterDistributionMaintainersResponse;
	"buildcenter/distributions/get": BuildCenterDistributionResponse;
	"buildcenter/distributions/list": BuildCenterDistributionsResponse;
	"buildcenter/distributions/list-extensions": BuildCenterDistributionExtensionsResponse;
	"buildcenter/distributions/list-inheritables": BuildCenterInheritablesResponse;
	"buildcenter/distributions/set-favorite": BuildCenterDistributionResponse;
	"buildcenter/distributions/update": BuildCenterDistributionResponse;
	"buildcenter/versions/abort-release": BuildCenterVersionResponse;
	"buildcenter/versions/create-redirect": BuildCenterVersionRedirectFormResponse;
	"buildcenter/versions/delete-build": BuildCenterDeleteBuildResponse;
	"buildcenter/versions/get": BuildCenterVersionResponse;
	"buildcenter/versions/get-logs": BuildCenterVersionLogsResponse;
	"buildcenter/versions/list-redirects": BuildCenterRedirectResponse;
	"buildcenter/versions/delete-redirect": BuildCenterVersionRedirectResponse;
	"buildcenter/versions/delete-all-redirects": BuildCenterVersionRedirectResponse;
	"buildcenter/versions/set-active": BuildCenterVersionResponse;
	"buildcenter/versions/set-self-redirect": BuildCenterVersionResponse;
	"buildcenter/versions/start-release": BuildCenterVersionResponse;
	"buildcenter/versions/rebuild-msi": SuccessResponse;

	// Catalogue
	"catalogue/portfolio/admins": PortfolioAdminsResponse;
	"catalogue/portfolio/details": PortfolioDetailsResponse;
	"catalogue/portfolio/list": PortfoliosResponse;
	"catalogue/portfolio/regenerate-url-db3": SuccessResponse | InfoResponse;

	// Customer
	"customer/agreements/list": CustomerAgreementsResponse;
	"customer/agreements/sign": SuccessResponse;
	"customer/agreements/update-cla": SuccessResponse;
	"customer/find-admin": FindCustomerAdminResponse;
	"customer/get": CustomerResponse;
	"customer/get-by-id": CustomerResponse;
	"customer/invoices/count-unpaid": CustomerCountUnpaidInvoicesResponse;
	"customer/list": CustomersResponse;
	"customer/log/list": CustomerLogsResponse;
	"customer/offline/list": CustomerOfflineListResponse;
	"customer/offline/unlock": SuccessResponse;
	"customer/twinmotion/get-purchase": CustomerTwinmotionPurchaseResponse;

	// Currency
	"currency/list-active": CurrenciesResponse;

	// Dev
	"dev/list-admins": DevListAdminsResponse;

	// Forum
	"forum/list": ForumsResponse;

	// Geography
	"geography/country/list": CountriesResponse;
	"geography/state/list": StatesResponse;

	// Internal awards
	"contentadmin/awards": GetAwardsResponse;
	"contentadmin/get-award": GetAwardResponse;
	"contentadmin/get-award-votes": GetAwardVoters;
	"contentadmin/remove-award": SuccessResponse;
	"contentadmin/remove-award-category": SuccessResponse;
	"contentadmin/pm-data/get": PmDataHW;
	"contentadmin/pm-data/list": ListPMDataResponse;
	"contentadmin/pm-data/link": SuccessResponse;
	"contentadmin/pm-data/unlink": SuccessResponse;

	// Internal logs
	"contentadmin/logs": LogsWithUsersResponse;

	// HookForm
	"hookform/example": SuccessResponse;

	// License Administration
	"license-administration/get-customer-id": CustomerIdResponse;
	"license-administration/groups/list": LicenseGroupsResponse;
	"license-administration/groups/delete": SuccessResponse;
	"license-administration/groups/list-admins": GroupAdminsResponse;
	"license-administration/groups/remove-admin": SuccessResponse;
	"license-administration/groups/list-customers": GroupCustomerResponse;
	"license-administration/groups/admin-permissions": GroupAdminPermissionsResponse;
	"license-administration/licenses/data": CustomerLicensesDataResponse;
	"license-administration/licenses/overview": LicenseOverviewResponse;
	"license-administration/licenses/export": LicensesExportResponse;
	"license-administration/licenses/move-user-licenses": SuccessResponse;
	"license-administration/licenses/move-unallocated": SuccessResponse;
	"license-administration/licenses/resend-validation": SuccessResponse;
	"license-administration/licenses/offline/preview": LicensesOfflinePreviewResponse;
	"license-administration/licenses/offline/users": LicensesOfflineUsersResponse;
	"license-administration/licenses/offline/add-users": SuccessResponse;
	"license-administration/licenses/offline/download": FileResponse;
	"license-administration/licenses/offline/download-all": FileResponse;

	// Economy
	"economy/active-license-summary": ActiveLicenseResponse;
	"economy/extend-trial-period": SuccessResponse;
	"economy/remove-catalogue-api-service": SuccessResponse;
	"economy/stage": StagePartners;
	"economy/filespace": ManufacturersFilespaceUsageResponse;
	"economy/filespace-block": SuccessResponse;
	"economy/update-filespace-status": SuccessResponse;

	// Marketplace
	"marketplace/list-categories": CategoriesResponse;
	"marketplace/list-manufacturers": MarketplaceManufacturerListResponse;
	"marketplace/manufacturers-report": ExportResponse;
	"marketplace/list-products": ProductsResponse;
	"marketplace/list-extension-portfolios": ExtensionMarketplacePortfoliosResponse;
	"marketplace/toggle-portfolio": SuccessResponse;
	"marketplace/process-checkout": MarketplaceProcessCheckoutResponse;

	// Multichannel Demo
	"multichanneldemo/drawings": DemoDrawingsResponse;
	"multichanneldemo/remove-drawing": DemoDrawingsResponse;
	"multichanneldemo/store/update-drawing": DemoUpdateDrawingResponse;
	"multichanneldemo/token": FrameTokenResponse;
	"multichanneldemo/stream-url": StreamUrlResponse;
	"multichanneldemo/options": MultichannelDemoOptionResponse;
	"multichanneldemo/get-stack": MultichannelDemoStackResponse;

	// Media kit
	"events/get-upcoming-events": UpcomingEventsResponse;

	// Products
	"prices/set/get": PriceSetResponse;
	"prices/set/delete": SuccessResponse;
	"prices/model/list-grandfathered": GrandfatheredPriceModelsResponse;
	"prices/model/get": PriceModelResponse;
	"prices/list-logs": PriceLogsResponse;
	"prices/list": PriceSetsResponse;
	"prices/discounts/update": SuccessResponse;
	"prices/discounts/list": PriceDiscountsExtensionResponse;

	// Products
	"products/getting-started-info": GettingStartedInfoResponse;

	// Projects
	"projects/activity": ActivityLogResponse;
	"projects/manufacturer/add-project-form-data": AddProjectFormData;
	"projects/all-sentry-organizations": SentryOrganizationsResponse;
	"projects/catalogues/add-to-stage": PortfolioResponse;
	"projects/catalogues/get": PortfolioResponse;
	"projects/catalogues/list": PortfoliosResponse;
	"projects/catalogues/list-languages": CatalogueLanguagesResponse;
	"projects/catalogues/remove-from-stage": PortfolioResponse;
	"projects/catalogues/revoke-access": SuccessResponse;
	"projects/catalogues/sign-cetcl": CatalogueStatus;
	"projects/catalogues/sign-cwalt": CatalogueStatus;
	"projects/catalogues/sign-stage-pro": CatalogueStatus;
	"projects/catalogues/status": CatalogueStatus;
	"projects/contacts/edit": ContactsResponse;
	"projects/contacts/edit-extension": ContactsExtensionResponse;
	"projects/contacts/list": ContactsResponse;
	"projects/contacts/list-extension": ContactsExtensionResponse;
	"projects/create-qr-issue": SuccessResponse;
	"projects/extension/export-versions": ExtensionVersionsExportResponse;
	"projects/extension/get": ExtensionResponse;
	"projects/extension/get-product": EditionProductResponse;
	"projects/extension/list-agreements": ExtensionAgreements;
	"projects/extension/list-portfolios": ExtensionPortfolioResponse;
	"projects/extension/list-available-portfolios": ExtensionPortfolioResponse;
	"projects/extension/unlink-portfolio": SuccessResponse;
	"projects/extension/load-population": ExtensionPopulationResponse;
	"projects/extension/remove": SuccessResponse;
	"projects/extension/statistics": ExtensionStatisticsResponse;
	"projects/extension/remove-developer": SuccessResponse;
	"projects/get": ProjectsResponse;
	"projects/get-royalty-bank": RoyaltyBankResponse;
	"projects/list-editions": EditionsResponse;
	"projects/manufacturer/add": SuccessResponse;
	"projects/manufacturer/get-all": AllManufacturersResponseLW;
	"projects/manufacturer/get": ManufacturerResponse;
	"projects/manufacturer/remove": SuccessResponse;
	"projects/manufacturer/show": SuccessResponse;
	"projects/manufacturer/update": SuccessResponse;
	"projects/project-access/get": ProjectAccessResponse;
	"projects/project-access/remove": SuccessResponse;
	"projects/remove-support-contact": SuccessResponse;
	"projects/manufacturer/revoke-access": SuccessResponse;
	"projects/roles/list": RolesResponse;
	"projects/roles/add": RoleResponse;
	"projects/royalty": RoyaltyResponse;
	"projects/set-support": SuccessResponse;
	"projects/sign-cetrpl-agreement": SuccessResponse;
	"projects/terminate-support-agreement": ExtensionAgreementResponse;
	"projects/favorite/add": SuccessResponse;
	"projects/favorite/remove": SuccessResponse;

	// Migration
	"projects/migration/get-manufacturer-release": ManufacturerReleaseResponse;
	"projects/migration/list-candidates": MigrationCandidatesResponse;
	"projects/migration/remove-manufacturer-release": SuccessResponse;

	// Release Statistics
	"releasesummary/list-release-summary": ManufacturerBySignedRelease;

	// Resources
	"resources/get-resources": ResourcesResponse;

	// Sharepoint
	"sharepoint/list-epics": JiraIssuesListResponse;
	"sharepoint/list-jira-issues": JiraIssuesListResponse;

	// Siteadmin
	"siteadmin/contacts/list-role": ContactsResponse;
	"siteadmin/contacts/list-role-extension": ContactsExtensionResponse;
	"siteadmin/cronjob/list": CronJobsResponse;
	"siteadmin/cronjob/run": CronJobActionResponse;
	"siteadmin/cronjob/stop": CronJobActionResponse;
	"siteadmin/cronjob/toggle-active": CronJobActionResponse;
	"siteadmin/cronjob/toggle-watch": CronJobActionResponse;
	"siteadmin/delete-api-key": DeleteAPIKeysResponse;
	"siteadmin/editions/form-data": EditionFormDataResponse;
	"siteadmin/editions/get": EditionResponse;
	"siteadmin/extensions": ExtensionsResponse;
	"siteadmin/list-api-keys": ListAPIKeysResponse;
	"siteadmin/list-different-group": ListGroupWithDifferentExpiredDatesResponse;
	"siteadmin/list-editions": ListEditionsResponse;
	"siteadmin/list-extension": ListExtensionWithDifferentExpiredDatesResponse;
	"siteadmin/list-licenses": LicenseResponse;
	"siteadmin/tasks/delete": SuccessResponse;
	"siteadmin/tasks/list": ListTasksResponse;
	"siteadmin/tasks/result": TaskResultResponse;
	"siteadmin/test-crash": TestCrashResponse;
	"siteadmin/uploads": FilesResponse;
	"siteadmin/cache-management/list": CacheManagementResponse;
	"siteadmin/cache-management/get": CacheKeyValueResponse;
	"siteadmin/cache-management/clear": SuccessResponse;
	"siteadmin/developers/list": SiteadminDeveloperResponse;
	"siteadmin/developers/delete": SuccessResponse;
	"siteadmin/developers/add": { uid: number };
	"siteadmin/developers/extensions/delete": SuccessResponse;
	"siteadmin/developers/extensions/save": SuccessResponse;
	"siteadmin/developers/extensions/list-all": SiteadminExtensionResponse;
	"siteadmin/sso/list": SiteadminExtensionResponse;
	"siteadmin/sso/get": SiteadminSSOAppResponse;

	// Support
	"support/get-licenses-to-extend": ExtensibleLicensesResponse;
	"support/list-agreement-titles": AgreementTitlesResponse;
	"support/list-agreements": AllTypeAgreementsResponse;
	"support/list-extension-agreements": ExtensionAgreements;
	"support/list-manufacturer-agreements": ManufacturerAgreements;
	"support/list-user-agreements": UserAgreements;
	"support/search-account": SearchAccountResponse;

	// Subscription
	"subscription/generate-summary": SummaryCartResponse;
	"subscription/list": SubscriptionsResponse;
	"subscription/list-licenses": SubscriptionLicensesResponse;
	"subscription/remove": SuccessResponse;
	"subscription/remove-product": SuccessResponse;
	"subscription/convert-autobilling": SuccessResponse;
	"subscription/list-payment-methods": SubscriptionsPaymentMethodsResponse;

	"subscription/stripe/checkout-session/get": StripeCheckoutSessionResponse;
	"subscription/stripe/checkout-session/create": StripeCreateCheckoutSessionResponse;

	// Support Tools
	"supporttools/campaigns/get": CampaignResponse;
	"supporttools/campaigns/list-all": CampaignsResponse;
	"supporttools/campaigns/list-ongoing": CampaignsResponse;
	"supporttools/campaigns/list-editions": ListEditionsResponse;
	"supporttools/campaigns/list-participants-by-campaign": CampaignParticipantsResponse;
	"supporttools/campaigns/logs": LogsWithUsersResponse;
	"supporttools/campaigns/merge-customers": SuccessResponse;
	"supporttools/campaigns/participants": CampaignParticipantsResponse;
	"supporttools/cetextensions/list": ExtensionInfoResponse;
	"supporttools/list-editions": ListEditionsResponse;
	"supporttools/manufacturers/list": AllManufacturersResponse;
	"supporttools/release-statistics/list-release-versions": ReleaseVersionsResponse;
	"supporttools/release-statistics/list-manufacturer-signed-release": ManufacturerBySignedRelease;
	"supporttools/release-statistics/list-unsigned-manufacturers": UnsignedManufacturersResponse;
	"supporttools/release-statistics/export": ExportResponse;
	"supporttools/drivers/uploads": FilesResponse;
	"supporttools/products/export": ExportResponse;

	// Upload
	"upload/credentials": UploadCredentialsResponse;
	"upload/delete": SuccessResponse;
	"upload/save": UploadSaveResponse;

	// User
	"user/get-logged-in": UserAdminResponse | InfoResponse;
	"user/get-tfa": { authenticated: boolean };
	"user/reports/users-using-manufacturers": ExportResponse;

	// Reports
	"reports/publishing-users-count": ExportResponse;

	// Other
	"drivers/get": DriversResponse;
	"myconfigura/accept-policy": SuccessResponse;
	"myconfigura/country-state": ContryStateResponse;
	"myconfigura/currency": CurrencyResponse;

	// Search
	"search/list-recent-searches": RecentSearchResponse;
	"search/save-search": SuccessResponse;
	"search/users": SearchUserLW;
	"search/customers": CustomerLW;
	"search/invoices": SearchInvoiceLW;
	"search/licenses": SearchLicense;
	"search/extensions": types.ExtensionLW;

	// Training
	"training/class": TrainingClassResponse;
	"training/date/activity-logs": TrainingDateActivityLogsResponse;
	"training/date/participants": TrainingDateParticipantsResponse;
	"training/list": TrainingClassListResponse;

	// Translations
	"translations/load": TranslationsResponse;

	// Translation admin
	"translations-admin/add": SuccessResponse;
	"translations-admin/create": SuccessResponse;
	"translations-admin/edit": SuccessResponse;
	"translations-admin/get": TranslationResponse;
	"translations-admin/import": SuccessResponse;
	"translations-admin/list": TranslationsByScopeResponse;
	"translations-admin/preview": TranslationsImportPreviewResponse;
	"translations-admin/scopes/list": TranslationScopesResponse;
	"translations-admin/search": TranslationsByScopeResponse;

	// Two Factor Authentication
	"two-factor-authentication/authenticate": TFAAuthenticationResponse;
	"two-factor-authentication/authentication/create": TFAAuthenticationResponse;
	"two-factor-authentication/authentication/get": TFAAuthenticationResponse;
	"two-factor-authentication/authentication/get-with-codes": TFAAuthenticationResponse;
	"two-factor-authentication/authentication/list": TFAAuthenticationsResponse;
	"two-factor-authentication/code/get": TFAAuthenticationCodeResponse;
	"two-factor-authentication/evaluate": TFAEvaluationResponse;
	"two-factor-authentication/logout": TFAAuthenticationResponse;

	// Status
	"status/aws": AwsStatus;
}

export type Endpoint = keyof Responses;

type FilterKeysByType<T, U> = { [K in keyof T]: T[K] extends U ? K : never }[keyof T];
export type ReportEndpoint = FilterKeysByType<Responses, ExportResponse>;

export function call<K extends Endpoint>(endpoint: K, data?: any, skipDebug?: boolean) {
	const url = urls.apiURL(endpoint);
	const promise = fetchJSON<Responses[K] | ErrorResponse | TfaRequiredResponse>(
		url,
		data,
		skipDebug
	)
		.then((response) => {
			if ("tfa_required" in response) {
				const nextURL = encodeURIComponent(window.location.toString());
				window.location.href = `${urls.APP_URL}/my/internal/two-factor-authentication/authenticate/${nextURL}`;
				return Error(`You need to be logged in with 2FA, redirecting`);
			} else if ("error" in response) {
				if (typeof response.error === "string") {
					return Error(response.error);
				} else {
					return new TranslatableError(response.error?.message, response.error?.values);
				}
			} else {
				return response;
			}
		})
		.catch(() => {
			return Error(
				`Unknown error, please try again or contact support if the error persists.`
			);
		});
	return toResult(promise);
}
